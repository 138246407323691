<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="card-header">
            <h5 class="card-title">Clients</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <div class="btn-group">
              <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div>
            <vue-good-table
              ref="clientsGrid"
              :theme="tableTheme"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,
                setCurrentPage: serverParams.page,

                position: 'bottom',
                perPageDropdown: [10,30,50],
                dropdownAllowAll: true,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
              :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="handleEdit(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import * as CONSTS from "../../../consts/constants";

export default {
  components: {},
  computed: {
    ...mapGetters(["userSelectedProduction", "gridStates", "tableTheme", 'tableTheme']),
    productionSelected() {
      return this.$store.getters.userSelectedProduction;
    }
  },
  watch: {
    productionSelected(userSelectedProduction, oldValue) {
      console.log("Clients productionSelected");
      // userSelectedProduction has changed (loaded)
      this.loadData();
    }
  },
  mounted() {
    this.updateTableFilters();
  },
  created() {
    let gridState = this.gridStates[this.gridName];

    if (gridState) {
      this.serverParams = gridState;
    }
    this.loadData();
  },
  data() {
    return {
      gridName: "clientsGrid",
      serverParams: {
        gridName: "clientsGrid",
        columnFilters: {},
        sort: {
          field: "company_name",
          type: "asc"
        },
        page: 1,
        perPage: 10
      },
      columns: [
        {
          label: "Actions",
          field: "actions"
        },
        {
          field: "alphabetLetter",
          label: "Letter",
          tdClass: "tablecell",
          thClass: "tablecell",
          // formatFn: this.formatAlphabet,
          // type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.alphabet
          }
        },
        {
          field: "company_name",
          label: "Client",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter client"
          }
        },
        {
          field: "full_name",
          label: "Sales Person",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sales Person"
            // filterValue: 'Mil'
          }
        },
          {
          field: "participant_creation_date",
          label: "Created",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatDate,
          tdClass: this.setCreateDateClass,
          filterOptions: {
            enabled: true,
            placeholder: "Filter"
          }
        },
        {
          field: "participant_stand_type",
          label: "Stand Type",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatStandType,
          // type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.stand_types
          }
        },
        {
          field: "action_outstanding",
          label: "Action Outstanding",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatAction,
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
         {
          field: "participant_bought",
          label: "Bought",
          tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatAction,
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.no_yes
          }
        },
        {
          field: "contact_heat",
          label: "Heat",
          // tdClass: "tablecell",
          thClass: "tablecell",
          formatFn: this.formatHeat,
          tdClass: this.setHeatClass,
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Filter",
            filterDropdownItems: CONSTS.strings.heat
          }
        },
        // {
        //   field: "contact",
        //   label: "Contact",
        //   tdClass: "tablecell",
        //   thClass: "tablecell",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Filter Email"
        //   }
        // },
        // {
        //   field: "email",
        //   label: "Email",
        //   tdClass: "tablecell",
        //   thClass: "tablecell",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Filter Email"
        //   }
        // },
        {
          field: "address_country",
          label: "Country",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Country"
          }
        },
         {
          field: "participant_sector",
          label: "Sector",
          tdClass: "tablecell",
          thClass: "tablecell",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Sector"
          }
        }

      ],
      rows: []
    };
  },
  methods: {
    formatDate(value) {

      var now = this.$moment();
      var createdDate = this.$moment(value);
      var days = now.diff(createdDate, 'days')

      if ( days < 5){
        // this is a new client

        return this.$moment(value).format("DD-MM-YYYY") + ' NEW';
      } else {

      return this.$moment(value).format("DD-MM-YYYY");
      }
    },
    //
    updateTableFilters() {
      for (let i = 0; i < this.columns.length; i++) {
        const col = this.columns[i];
        if (typeof col.filterOptions !== "undefined") {
          let filterVal = {};

          if (
            (filterVal = this.serverParams.columnFilters[this.columns[i].field])
          ) {
            let filter = this.columns[i].filterOptions;
            filter.filterValue = filterVal;
            // seems bonkers having to do this
            let filterString = JSON.stringify(filter);
            let filterObj = JSON.parse(filterString);

            this.$set(this.columns[i], "filterOptions", filterObj);
          }
        }
      }
    },
    resetTable: function() {
      this.$refs["clientsGrid"].reset();
      this.serverParams.columnFilters = [];
      this.$store.dispatch("setGridState", this.serverParams);
    },

    // table events
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.$store.dispatch("setGridState", this.serverParams);
    },

    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.$store.dispatch("setGridState", this.serverParams);
    },

    onSortChange(params) {
      if (params.columnIndex){
        
        let newSort = {
          type: params.sortType,
          field: this.columns[params.columnIndex].field
        };

        this.serverParams.sort = newSort;
        this.$store.dispatch("setGridState", this.serverParams);
      }
    },

    onColumnFilter(params) {
      this.serverParams.columnFilters = params.columnFilters;
      this.$store.dispatch("setGridState", this.serverParams);
    },

    // end table events

    searchArray: function(array, key, prop) {
      // Optional, but fallback to key['name'] if not selected
      prop = typeof prop === "undefined" ? "name" : prop;

      for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === key) {
          return array[i];
        }
      }
      return null;
    },
    formatStandType: function(value) {
      let obj = this.searchArray(CONSTS.strings.stand_types, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatAction: function(value) {
      let obj = this.searchArray(CONSTS.strings.no_yes, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    formatExportToAccounts: function(value) {
      let obj = this.searchArray(CONSTS.strings.exportedtosage, value, "value");
      if (obj) {
        return obj.text;
      }
    },

    setCreateDateClass: function(row) {

      var now = this.$moment();
      var createdDate = this.$moment(row.participant_creation_date);
      var days = now.diff(createdDate, 'days')

      if ( days < 5){
        // this is a new client
        return "Hot";
      }


    },

    setHeatClass: function(row) {
      switch (row.contact_heat) {

        case 1:
          return "Arctic";
        case 2:
          return "Cool";
        case 3:
          return "Warm";
        case 4:
          return "Hot";
        case 5:
          return "Yes";
        case 6:
          return "No";
        case 7:
          return "CallingBack";
        case 8:
          return "Snatch";
        case 9:
          return "Assigned";
        case 10:
          return "Repeat";
        case 11:
          return "LastYear";
        case 12:
          return "NewatShow";
        case 13:
          return "Prospect";
        case 14:
          return "HideDiscount";
        case 15:
          return "Inactive";
        case 16:
          return "Emailed";
      }
    },
    formatHeat: function(value) {
      let obj = this.searchArray(CONSTS.strings.heat, value, "value");
      if (obj) {
        return obj.text;
      }
    },
    loadData: function() {


      this.$http
        .get("sales/client/" + this.userSelectedProduction)
        .then(response => {
          this.rows = response.data

          // set the first letter field
          for (let i=0; i< this.rows.length; i++){
            var res = this.rows[i].company_name.charAt(0);
            res = res.toLowerCase()
            this.rows[i]['alphabetLetter'] = res
          }
        })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message:
              "There was a problem loading the clients. Please try again.",
            type: "warning"
          });
        });
    },
    handleEdit(index, row) {
      try {
        this.$router.push({ name: "Client", params: { id: row.guid } });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style lang="scss">
.tablecell {
  font-size: 14px;
}

  // heat
.Arctic {
    // background-color: #b9e119;
    background: rgba(185,225,25, 0.3);
    color:#ffffff;
}
.Cool {
    // background-color: #66CCFF;
    background: rgba(102,204,255, 0.3);
    color:#ffffff;
}
.Warm {
    // background-color: #FF9900;
    background: rgba(255,153,0, 0.3);
    color:#ffffff;
}
.Hot {
    // background-color: #FF3333;
    background: rgba(255,51,51, 0.3);
    color:#ffffff;
}
.Yes {
    // background-color: #00CC00;
    background: rgba(0,204,0, 0.3);
    color:#ffffff;
}
.No {
    // background-color: #666666;
    background: rgba(102,102,102, 0.3);
    color:#ffffff;
}
.CallingBack {
    // background-color: #9966FF;
    background: rgba(153,102,255, 0.3);
    color:#ffffff;
}
.Snatch {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.Assigned {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.Repeat {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.LastYear {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.NewatShow {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.Prospect {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.HideDiscount {
    // background-color: #CC3399;
    background: rgba(204,51,153, 0.3);
    color:#ffffff;
}
.Inactive {
    // background-color: #990099;
    background: rgba(153,0,153, 0.3);
    color:#ffffff;
}
.Emailed {
    // background-color: #990099;
    background: rgba(153,0,153, 0.3);
    color:#ffffff;
}



</style>
